import React from 'react';
import styles from './article.module.css';

const article = (props) => {

    const articleStyles = [styles.article];
    if(props.type) articleStyles.push(styles[props.type])
    return (
        <div className={articleStyles.join(' ') }>
            {props.children}
        </div>
    )
}

export default article;