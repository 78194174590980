import React, { Fragment, useState } from 'react';

import styles from './topNav.module.css';


const TopNav = props => {

    const [menuState, setMenuState] = useState(false);
    
    const HOME_LOC = 0;
    const ABOUT_LOC = 1;
    const DOGS_LOC = 2;
    const ENQ_LOC = 3;
    const FEED_LOC = 4;

    const goTo = destination => {
        console.log(destination);
        switch(destination){
            case HOME_LOC:
                window.scroll(0);
                break;
            case ABOUT_LOC:
                document.getElementById('aboutUsTitle').scrollIntoView({behavior:'smooth'});
                break;
            case DOGS_LOC:
                document.getElementById('ourDogsTitle').scrollIntoView({ behavior: 'smooth' });
                break;
            case ENQ_LOC:
                document.getElementById('enquiriesTitle').scrollIntoView({ behavior: 'smooth' });
                break;
            case FEED_LOC:
                document.getElementById('feedTitle').scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    }

    const menuToggle = () => {
        setMenuState( prevState => {
            const newState = !prevState;
            return newState;
        })
    }

    const menuStyle = (menuState) ? {display:'block'} : {};
    
    return (
        <Fragment>
        <div id={styles.nav}>
            <div className={styles.hamburger} onClick={menuToggle}>
                <div className={styles.bun}></div>
                <div className={styles.bun}></div>
                <div className={styles.bun}></div>
            </div>
            <ul id={styles.menu} style={menuStyle} >
            <li>
                <button onClick={() => goTo(ABOUT_LOC)}>About Us</button>
            </li>
            <li>
                <button onClick={() => goTo(DOGS_LOC)}>Our Dogs</button>
            </li>
            <li>
                <button onClick={() => goTo(ENQ_LOC)}>Enquiries</button>
            </li>
            <li>
                <button onClick={() => goTo(FEED_LOC)}>Feed</button>
            </li>
            </ul>
            <div className={styles.underline}></div>
        </div>
        <div className={styles.margin}></div>
        </Fragment>
    );
    
}

export default TopNav;