import React, { Component, Fragment } from 'react';

import Landing from './landing/landing';
import Section from './section/section';
import Article from './article/article';
import Carousel from './carousel/carousel';
import Hover from './hover/hover';
import Title from './title/title';
import TopNav from './nav/topNav/topNav';
import EnquiryForm from "../forms/EnquiryForm";
import Tumblr from '../tumblr/Tumblr';

import fern from '../../assets/images/fern.jpg';
import willow from '../../assets/images/willow2.jpg';
import purdey from '../../assets/images/purdey.jpg';
import tony from '../../assets/images/tony.jpg';
import bella from '../../assets/images/bella.jpg';
import cooper from '../../assets/images/cooper.jpg';

class Layout extends Component{

  render() {
      return (
        <Fragment>
          <TopNav />
          <Landing />
          <Section type='full'>
            <Carousel type='static' />
          </Section>
          <Title id='aboutUsTitle'>About Us</Title>
          <Section>
            <Article type="half">
              <div className="textpane">
                <h1 className="heading">Welcome to Sheeptown Gundogs</h1>
                <p>
                  Sheeptown is a small private kennels based in Surrey
                  specialising in gundogs with trainer Tony Hall. Tony has
                  been training his own working dogs for the past 40 years but
                  got his first Labrador 9 years ago after getting back into
                  shooting. Tony now helps run a 500 acre pheasant shoot as
                  well as access to small rough shoots and trout lakes with
                  plenty of opportunity and land to train his dogs.
                </p>
                <p>
                  Follow our progress from pup to pick up with our litter born
                  May 2020!
                </p>
              </div>
            </Article>
            <Article type="half">
              <img src={tony} alt="Tony and Fern" />
            </Article>
          </Section>
          <Title id='ourDogsTitle'>Our Dogs</Title>
          <Section height='600'>
            <Article type="half">
              <Hover img={purdey} title="Purdey">
                <p>
                  <i>Ares Burford</i>
                </p>
                <p>
                  Now semi retired, Purdey was Tonys first Labrador.</p>
                <p>With a
                strong intuitive nature she was a great first dog to work on
                  different teaching methods.</p>
                <p>Purdey became a brilliant wild fouling dog.</p>
              </Hover>
            </Article>
            <Article type="half">
              <Hover img={willow} title="Willow">
                <p>
                  <i>Hawks Medow Bella</i>
                </p>
                <p>
                  Willow was an extremely hard working and fast Labrador but
                  her highly driven nature was not suited to the hustle and
                  bustle of a pheasant shoot.</p>
                <p>A very intelligent dog and a
                  pleasure to train, she is now retired with Tonys daughter
                  and her young family.
                </p>
              </Hover>
            </Article>
          </Section>
          <Section height='600'>
            <Article type="half">
              <Hover img={fern} title="Fern">
                <p>
                  Trained for a gun on the shoot, Fern was the first Coker
                  Spaniel at Sheeptown. Tony found it much easier to begin
                  training Fern with place mats as she bonded very quickly, so this
                  method helped to steady her.</p>
                <p>She was a joy to train and eager to train another.</p>
                <p>Fern is now a falconry dog flushing for hawks</p>
              </Hover>
            </Article>
            <Article type="half">
              <Hover img={bella} title="Bella">
                <p>
                  Bella works
                  for 8 months of the season on our pheasant shoot right through to partridge, pheasant, duck and goose.</p>
                  <p>Bella is calm and soft natured and soft mouthed.</p>
                  <p>She is calm when not working and enthusiastic on the field.</p>
              </Hover>
            </Article>
          </Section>
          <Title id='enquiriesTitle'>Enquiries</Title>
          <Section>
            <Article type="Half">
              <img src={cooper} alt='Cooper' />
            </Article>
            <Article type="half">
              <div className="textpane">
                <p>Please fill in the form below to get in contact about training.</p>
                <EnquiryForm />
              </div>
            </Article>
          </Section>
          < Title id='feedTitle'> Feed </Title>
          <Section>
            <Tumblr />
          </Section>
        </Fragment>
      );
  }
}

export default Layout;