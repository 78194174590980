import React from 'react';
import styles from './container.module.css';

const container = (props) => {
    const contentsStyles = styles.contents+" clearfix";
    const containerStyles = styles.container+" shadowed";
    return (
        <div className={containerStyles}>
            <h1>{props.title}</h1>
            <div className={contentsStyles}>
                {props.children}
            </div>
        </div>
    );

}


export default container;