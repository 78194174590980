import React, { useState } from 'react';
import styles from './hover.module.css';

 const Hover = props => {

    const [showing, setShowing] = useState(false);
    const infoStyles = [styles.info];
    
    if (showing) infoStyles.push(styles.showing);

    return (
        <div
            className={styles.hover}
            onMouseEnter={() => setShowing(true)}
            onMouseLeave={() => setShowing(false)}
            style={{backgroundImage:`url('${props.img}')`}}
        >
        <div className={infoStyles.join(" ")}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.text}>{props.children}</div>
        </div>
        </div>
    );
}

export default Hover;