import React from "react";
import styles from "./logo.module.css";

const landing = () => {
  return (
    <div className={styles.logo} />
  );
};

export default landing;
