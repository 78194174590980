import React, { useState, Fragment } from 'react';
import Firebase from '../../axios_firebase';
import emailjs from 'emailjs-com';

import { EMAILJS_ID, RECAPTCHA_KEY } from '../../conf';
import styles from './enquiryForm.module.css';
import Input from '../ui/form/input/input';
import Button from '../ui/form/button/button';

const EnquiryForm = React.memo( props => {

    const [telState, setTelState] = useState('');
    const [nameState, setNameState] = useState('');
    const [emailState, setEmailState] = useState('');
    const [enquiryState, setEnquiryState] = useState('');
    const [errorState, setErrorState] = useState([]);
    const [sentState, setSentState] = useState(false);

    const formHndlr = (event) => {
        event.preventDefault();

        console.log(event.target);
        console.log(document.getElementById('enquiryForm'));

        const errors = [];
        if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailState))){
            errors.push('Please enter a valid email address');
        }
        if (enquiryState.length < 15) {
            errors.push('Please enter an enquiry');
        }
        if (nameState.length < 2) {
            errors.push('Please enter your name');
        }
        if(errors.length > 0){
            setErrorState(errors);
            return;
        }

        var template_params = {
            "enquiry_name": nameState,
            "enquiry_date": new Date().toUTCString(),
            "enquiry_message": enquiryState,
            "enquiry_email": emailState,
            "enquiry_phone": telState
        }

        var service_id = "default_service";
        var template_id = "sheeptown";
        
        emailjs.send(service_id, template_id, template_params, EMAILJS_ID)
        .then( r => {
            Firebase.post('enquiries.json', {
                name: nameState,
                email: emailState,
                telephone: telState,
                enquiry: enquiryState,
                date: new Date()
            })
        })
        .then( res => {
            setSentState(true);
            setErrorState([])
        })
        .catch( err => {
            setErrorState(["Please ensure you are not a robot!"])
        })
    }

    let errorArea = null;
    if(errorState.length > 0) {
        errorArea = (
            <div className={styles.errorResponse}>
                <ul>
                    {errorState.map( (err,index) => <li key={index}>{err}</li>)}
                </ul>
            </div>
        )
    }

    let formArea = (
        < form key='form' id='enquiryForm' >
            <Input
                key='telephoneInput'
                name='Telephone'
                type='number'
                value={telState}
                placeholder='Telephone'
                changed={(event) => setTelState(event.target.value)} />
            <Input
                key='emailInput'
                name='Email'
                type='email'
                value={emailState}
                placeholder='Email'
                required='true'
                changed={(event) => setEmailState(event.target.value)} />
            <Input
                key='nameInput'
                name='Name'
                type='text'
                value={nameState}
                placeholder='Name'
                required='true'
                changed={(event) => setNameState(event.target.value)} />
            <Input
                key='enquiry'
                name='Enquiry'
                type='textarea'
                value={enquiryState}
                placeholder='Enquiry'
                required='true'
                changed={(event) => setEnquiryState(event.target.value)} />
            <div className="g-recaptcha" data-sitekey={ RECAPTCHA_KEY}></div>
            <Button type='general' clicked={formHndlr}>Submit</Button>
        </form>
    );
    
    if(true === sentState) {
        formArea = (
            <div className={styles.sentMessage}>
                <p>Thanks for your enquiry!</p>
            </div>
        )
    }

    return (
        <Fragment>
            {errorArea}
            {formArea}
        </Fragment>
    )
})

export default EnquiryForm;