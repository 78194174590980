import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './components/pages/home/Home';

import Layout from './components/layout/layout';
import './App.css';

const Dogs = React.lazy(() => import('./components/pages/dogs/dogs'));
const Enquiries = React.lazy(() => import('./components/pages/enquiries/enquiries'));
const AdminEnquiries = React.lazy(() => import('./components/pages/admin/enquiries/Enquiries'));
const About = React.lazy(() => import('./components/pages/about/about'));

function App () {
  return (
    <Layout>
      <Switch>
        <Route path='/about-us' render={() => <Suspense fallback={<div>Loading...</div>}><About /></Suspense>}  />
        <Route path='/our-dogs' render={() => <Suspense fallback={<div>Loading...</div>}><Dogs /></Suspense>} />
        <Route path='/enquiries' render={() => <Suspense fallback={<div>Loading...</div>}><Enquiries /></Suspense>} />
        <Route path='/admin/enquiries' render={() => <Suspense fallback={<div>Loading...</div>}><AdminEnquiries /></Suspense>} />
        <Route path='/' exact component={ Home } />
        <Redirect to='/' />
      </Switch>
    </Layout>
  );
}

export default App;
