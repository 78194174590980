import React from 'react';
import styles from './tumblr.module.css';

const tumblrImg = (props) => {
    return (
        <div className={styles.post}>
            <img src={props.img} style={{ height: "100%" }} alt={props.children} />
            <div className={styles.text}>{props.children}</div>
        </div>
    );
}


export default tumblrImg;