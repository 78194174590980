import React, { useState } from 'react';

export const AuthContext = React.createContext({
    isAuth:false,
    login: () => {}
});

const AuthContextProvider = props => {
    const [isAuthenticated, setAuthenticated] = useState(false);

    const loginHndlr = () => {
        setAuthenticated(true);
    }
    
    return (
        <AuthContext.Provider value={{login: loginHndlr, isAuth: isAuthenticated}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;