import React from 'react';
import styles from './landing.module.css'
import Logo from '../logo/logo';

const landing = () => {
    
    return (
      <div className={styles.landing}>
        <Logo />
      </div>
    );
}

export default landing;