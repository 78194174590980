import React from 'react';
import styles from './section.module.css';

const section = (props) => {
    const sectionStyles = [styles.section];
    if('full' === props.type){
        sectionStyles.push(styles.full)
    } else {
        sectionStyles.push(styles.buffered)
    }
    const inlineStyle = {};
    if(props.height) {
        inlineStyle.height = `${props.height}px`;
    }
    return (
        <div className={sectionStyles.join(' ')} style={inlineStyle}>
            {props.children}
        </div>
    )
}

export default section;