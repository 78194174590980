import React, { Component } from 'react';

import TumblrImg from './TumblrImg';
import style from './tumblr.module.css';
import * as conf from '../../conf';

class Tumblr extends Component {

    state = {
        posts:[]
    }

    componentDidMount() {
        const tumblr = require('tumblr.js');
        var client = tumblr.createClient({ consumer_key: conf.TUMBLR_CONSUMER_KEY});
        
        let tags = [];
        if(this.props.tags){
            tags = this.props.tags.split(",");
        }

        client.blogPosts('sheeptowngundogs.tumblr.com', {type:'photo', tag:tags, limit:8 }, (err,resp) => {
            if(!err && resp.posts) {
                this.setState({ posts: resp.posts.map( post => {
                    //const image = post.body.match(/(?<=<img src=")[^"]*(?=")/);
                    //const text = post.body.match(/(?<=<\/figure>).*/);
                    const text = post.body.match(/<\/figure>(.*)/);
                    const image = post.body.match(/<img src="([^"]+)/);
                    const stripped = text[1].replace(/<\/p>/g,'\n').replace(/<[^>]+>/g,'');
                    return {
                        key: post.id_string,
                        src: image[1],
                        text: stripped,
                        date: new Date(post.date)
                    }
                }) });
            }
        });
    }

    createMarkup = html => ({__html: html});
    
    render() {
        
        let posts = this.state.posts.map(post => (
                <TumblrImg key={post.key} date={post.date} img={post.src} >
                    {post.text}
                </TumblrImg>
            )
        );
            
        return (
            <div className={style.feed}>
                {posts}
            </div>
        )
    }
}

export default Tumblr;