import React, { Component } from "react";
import styles from "./carousel.module.css";



class Carousel extends Component {
    FORWARDS = 0;
    BACKWARDS = 1;

    constructor(){
        super();

        this.state = {
            carouselPos: 33.333,
            frameStyle: {transform: "translateX(-33.333%)"},
            transitioning: false,
            carouselArray: [
              <div className={styles.pic1}></div>,
              <div className={styles.pic2}></div>,
              <div className={styles.pic3}></div>
            ]
        }
    }

    move = (direction) => {
      if(true === this.state.transitioning) {
        return;
      }

      let carouselX = this.state.carouselPos;
      switch(direction){
          case this.BACKWARDS:
              carouselX += 33.333;
              break;
          case this.FORWARDS:
              carouselX -= 33.333;
              break;
          default:
              break;
      }
      this.setState(prev => ({
          ...prev,
          frameStyle: {
              ...prev.frameStyle, 
              transition: 'all 1s ease-out', 
              transform:'translateX(-'+carouselX+'%)'
          },
          carouselPos: carouselX,
          transitioning: true
      }));
      setTimeout( () => this.reset(direction), 1000);
    };

    reset = direction => {
        // instantly move carousel back to middle with whatever's in 3 in 2 and whatever's in 1 in 3
        let tempArray = [...this.state.carouselArray];
        if(direction === this.BACKWARDS){
          let first = tempArray.shift();
          tempArray.push(first);
        } else {
          let last = tempArray.pop();
          tempArray.unshift(last);
        }

        this.setState((prev) => ({
          ...prev,
          frameStyle: {
            ...prev.frameStyle,
            transition: "none",
            transform: "translateX(-33.333%)",
          },
          carouselPos: 33.333,
          carouselArray: tempArray,
          transitioning: false
        }))

        if('auto' === this.props.type){
          setTimeout(() => this.move(this.BACKWARDS), 4000);
        }
    }


    componentDidMount(){
      if('auto' === this.props.type){
        setTimeout(() => this.move(this.BACKWARDS), 6000);
      }
    }

    render() {
        const carouselStyles = [styles.carousel];

        let controls = null;
        if ("manual" === this.props.type) {
          let controlStyles = [styles.control];
          if(this.state.transitioning){
            controlStyles.push(styles.disabled)
          }
          controls = (
            <div className={styles.controls}>
              <div
                className={styles.left + " " + controlStyles.join(" ")}
                onClick={() => this.move(this.BACKWARDS)}
              >
                <h2>&lt;</h2>
              </div>
              <div
                className={styles.right + " " + controlStyles.join(" ")}
                onClick={() => this.move(this.FORWARDS)}
              >
                <h2>&gt;</h2>
              </div>
            </div>
          );
        }
        
        if (this.props.type) carouselStyles.push(styles[this.props.type]);
        return (
          <div id="carousel" className={carouselStyles.join(" ")}>
            {controls}
            <div
              id="frame"
              className={styles.frame}
              style={this.state.frameStyle}
            >
              <div id="carouselLeft" className={styles.picture}>
                {this.state.carouselArray[this.state.carouselArray.length - 1]}
              </div>
              <div id="carouselMid" className={styles.picture}>
                {this.state.carouselArray[0]}
              </div>
              <div id="carouselRight" className={styles.picture}>
                {this.state.carouselArray[1]}
              </div>
            </div>
          </div>
        );
    }
    
};

export default Carousel;
