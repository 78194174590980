import React, { Component } from 'react';

import Tumblr from '../../tumblr/Tumblr'
import Container from '../../ui/container/container';

class Home extends Component {

    render() {
        return (
            <Container title='Home'>
                <Tumblr/>
            </Container>
        )
    }
}

export default Home;