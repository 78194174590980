import React from 'react';
import styles from './input.module.css';

const input = (props) => {
    let input = null;
    switch (props.type){
        case 'text':
            input = <input 
                ref={props.reference}
                className={styles.input} 
                key={props.name} 
                id={props.name} 
                value={props.value} 
                placeholder={props.placeholder} 
                type='text'
                onChange={props.changed} />
            break;
        case 'email':
            input = <input
                className={styles.input}
                key={props.name}
                id={props.name}
                value={props.value}
                placeholder={props.placeholder}
                type='email'
                onChange={props.changed}  />
            break;
        case 'number':
            input = <input
                className={styles.input}
                key={props.name}
                id={props.name}
                value={props.value}
                placeholder={props.placeholder}
                type='number'
                onChange={props.changed} />
            break; 
        case 'textarea':
            input = <textarea
                className={styles.input}
                key={props.name}
                id={props.name}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.changed} />
            break;
        default:
            input = <input 
                className={styles.input}
                key={props.name}
                id={props.name}
                value={props.value}
                placeholder={props.placeholder}
                type='text'
                onChange={props.changed}  />
    }

    let required = null;
    if(props.required) {
        required = <span className={styles.required} title='required'>*</span>
    }
    return (
        <div className={styles.row}>
            <label className={styles.label} htmlFor={props.name}>{props.name}{required}</label>
            {input} 
        </div>
    )
}

export default input;